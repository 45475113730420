import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { usePageConfig } from "../../../hooks";
import { IFilter } from "../../../types/interfaces";
import { isProduction } from "../../../utils";
import { useDateContext } from "../../analyze/context";
import { useGetAllChatHistoryIdQuery } from "../services";
import { IMessage } from "../types";
import useGetHistoryRecords from "./useGetHistoryRecords";
// const MockId = "70790fa7-c89e-49d8-b0d0-3e9b98a3a188";//有多筆對話紀錄的ID
const PAGE_LIMIT = 7;
const initialFilter = ({ id }: { id: string }): IFilter[] => [
    {
        field: "charactor_id", //後端是charactor_id不是character_id
        operator: "=",
        value: id,
    },
    {
        field: "created_at",
        operator: "between",
        value: [dayjs.utc().startOf("month").toISOString(), new Date().toISOString()],
    },
];
const useGetHistory = () => {
    const { characterId } = useParams();
    const { handlePageChange, currentPage } = usePageConfig({
        pageLimit: PAGE_LIMIT,
    });
    const { dateRange, handleStartDate, handleEndDate } = useDateContext();
    const [filter, setFilter] = useState<IFilter[]>(initialFilter({ id: characterId as string }));
    const [chatRecordMessages, setChatRecordMessages] = useState<IMessage[]>([]);
    const [historyRecordId, setHistoryRecordId] = useState("");
    const selectedCharacterId = isProduction ? (characterId as string) : characterId || "";
    const dateStart = dateRange.started_at?.toISOString();
    const dateEnd = dateRange.ended_at?.toISOString();

    const {
        data: historyData,
        isLoading: idsLoading,
        error,
    } = useGetAllChatHistoryIdQuery(
        {
            page: currentPage,
            limit: PAGE_LIMIT,
            filter,
            order_by: "created_at desc ",
        },
        { skip: !characterId }
    );
    const handleSubmit = () => {
        setFilter([
            {
                field: "charactor_id", //後端是charactor_id不是character_id
                operator: "=",
                value: selectedCharacterId,
            },
            {
                field: "created_at",
                operator: "between",
                value: [dateStart ?? "", dateEnd ?? ""],
            },
        ]);
    };
    const handleGetRecentRecords = (days: number) => {
        handleStartDate(dayjs.utc().subtract(days, "day"));
        setFilter([
            {
                field: "charactor_id", //後端是charactor_id不是character_id
                operator: "=",
                value: selectedCharacterId,
            },
            {
                field: "created_at",
                operator: "between",
                value: [dayjs.utc().subtract(days, "day").toISOString(), dateEnd ?? ""],
            },
        ]);
    };

    const histories = useMemo(() => historyData?.data?.records || [], [historyData]);
    const historyCount = historyData?.data?.total_count || 0;
    const pageCount = Math.ceil((historyData?.data?.total_count ?? 0) / PAGE_LIMIT);
    const { chatRecords, chatLoading } = useGetHistoryRecords({ historyRecordId });
    const handleEditClick = (id: string) => {
        setHistoryRecordId(id);
    };

    useEffect(() => {
        if (chatRecords) {
            setChatRecordMessages(chatRecords.messages);
        }
        if (!historyRecordId) setChatRecordMessages([]);
    }, [chatRecords, historyRecordId]);

    useEffect(() => {
        if (histories === null || []) {
            setHistoryRecordId("");
        }
        if (histories.length > 0) {
            setHistoryRecordId(histories[0]?.id);
        }
    }, [histories]);
    return {
        error,
        histories,
        dateRange,
        handleStartDate,
        handleEndDate,
        historyCount,
        isLoading: idsLoading,
        handlePageChange,
        pageCount,
        handleEditClick,
        currentPage,
        historyRecordId,
        chatRecordMessages,
        chatLoading,
        handleSubmit,
        handleGetRecentRecords,
    };
};

export default useGetHistory;
