import React from "react";

import { EnterprisePermissionControl } from "../features/authentication/components/permissionControl";

const KnowledgeLayout = React.lazy(() => import("../features/characters/components/layout/KnowledgeLayout"));
const KnowledgeRecordLayout = React.lazy(
    () => import("../features/characters/components/layout/KnowledgeRecordLayout")
);

const CreateKnowledgePage = React.lazy(() => import("../pages/knowledges/CreateKnowledgePage"));
// const PublicKnowledgePage = React.lazy(() => import("../pages/knowledges/PublicKnowledgePage"));

const ManagePages = {
    SearchAllRecords: React.lazy(() => import("../pages/knowledges/SearchAllRecordsPage")),
    InuseKnowledge: React.lazy(() => import("../pages/knowledges/KnowledgeInusePage")),
    MineKnowledge: React.lazy(() => import("../pages/knowledges/MineKnowledgePage")),
    ExportImportKnowledge: React.lazy(() => import("../pages/knowledges/KnowledgeExportImportPage")),
};
const RecordPages = {
    EditKRecord: React.lazy(() => import("../pages/knowledges/record/EditKRecordPage")),
    RecycleBin: React.lazy(() => import("../pages/knowledges/record/RecycleBinPage")),
    ManualGenerator: React.lazy(() => import("../pages/knowledges/record/ManualGeneratorPage")),
    SmartGenerator: React.lazy(() => import("../pages/knowledges/record/SmartGeneratorPage")),
    KnowledgeRecords: React.lazy(() => import("../pages/knowledges/record/KnowledgeRecordsPage")),
    KnowledgeSettings: React.lazy(() => import("../pages/knowledges/KnowledgeSettingsPage")),
};

const ManageRoutes = [
    {
        element: <ManagePages.SearchAllRecords />,
        path: "search-all-record",
    },
    {
        element: <ManagePages.InuseKnowledge />,
        path: "inuse-knowledge",
    },
    {
        element: <ManagePages.MineKnowledge />,
        path: "mine-knowledge",
    },
    {
        element: <ManagePages.ExportImportKnowledge />,
        path: "export-import-knowledge",
    },
    {
        element: <CreateKnowledgePage />,
        path: "create-knowledge",
    },
    //! [2024_0614]這裡暫時用不到，但為確保以後需要的時候不用重新開發，所以先註解保留
    // {
    //     element: <PublicKnowledgePage />,
    //     path: "public-knowledge",
    // },
];
const RecordRoutes = [
    {
        element: <RecordPages.SmartGenerator />,
        path: "smart-generator",
    },
    {
        element: <RecordPages.ManualGenerator />,
        path: "manual-generator",
    },
    {
        element: <RecordPages.KnowledgeSettings />,
        path: "knowledge-settings",
    },
    {
        element: <RecordPages.KnowledgeRecords />,
        path: "knowledge-records",
    },
    {
        element: <RecordPages.RecycleBin />,
        path: "knowledge-records-recycle-bin",
    },
];
const withKnowledgePermission = (Component: React.ReactNode) => (
    <EnterprisePermissionControl resource='knowledge' action='view'>
        {Component}
    </EnterprisePermissionControl>
);
const KnowledgeRoutes = [
    {
        element: withKnowledgePermission(<KnowledgeLayout />),
        path: "",
        children: ManageRoutes,
    },
    {
        path: ":knowledgeId",
        element: withKnowledgePermission(<KnowledgeRecordLayout />),
        children: RecordRoutes,
    },
    {
        path: ":knowledgeId",
        children: [
            {
                path: ":recordId",
                children: [
                    {
                        element: withKnowledgePermission(<RecordPages.EditKRecord />),
                        path: "edit-knowledge-record",
                    },
                ],
            },
        ],
    },
];

export default KnowledgeRoutes;
