import { exportToExcel } from "react-json-to-excel";

import { customToast } from "../../../components/notify";
import { isDev } from "../../../utils";
import { DateRange } from "../../analyze/context/DateProvider";
import { useDownloadExcelMutation, useDownloadPictureMutation } from "../services";
interface IBaseReq {
    projectId: string | undefined;
    dateRange: DateRange;
}
interface IHandleDownloadExcelReq extends IBaseReq {
    projectName: string | undefined;
}
const useDownloadRecords = () => {
    const [downloadPicture, isFetching] = useDownloadPictureMutation();
    const [downloadExcel, { isLoading: isDownloadExcelLoading }] = useDownloadExcelMutation();
    const handleDownload = async ({ dateRange, projectId }: IBaseReq) => {
        if (!dateRange.started_at || !dateRange.ended_at || !projectId) return;
        const reqBody = {
            project_id: isDev ? "d65682c5-4829-4828-ad36-a4fd7912efde" : projectId,
            start_time: dateRange.started_at.toISOString(),
            end_time: dateRange.ended_at.toISOString(),
        };
        try {
            const res = await downloadPicture(reqBody).unwrap();
            if (res.status === "success") {
                window.open(res.zip_url, "_blank");
                customToast.success(res.message);
            }
        } catch (error) {
            //console.error(error);
        }
    };
    const handleDownloadExcel = async ({ projectId, dateRange, projectName }: IHandleDownloadExcelReq) => {
        if (!dateRange.started_at || !dateRange.ended_at || !projectId || !projectName) return;
        const reqBody = {
            project_uuid: projectId,
            project_name: projectName,
            start_time: dateRange.started_at.toISOString(),
            end_time: dateRange.ended_at.toISOString(),
        };
        try {
            const res = await downloadExcel(reqBody).unwrap();
            const fileName = `${projectName}_${dateRange.started_at.format("YYYYMMDD")}_${dateRange.ended_at.format(
                "YYYYMMDD"
            )}`;
            const exportExcel = Object.entries(res).map(([key, value]) => {
                if (!value) return { sheetName: key, details: [] };
                return { sheetName: key, details: value };
            });
            exportToExcel(exportExcel, fileName, true);
        } catch (error) {
            //console.error(error);
        }
    };
    return { downloadPicture, handleDownload, handleDownloadExcel, isFetching, isDownloadExcelLoading };
};
export default useDownloadRecords;
