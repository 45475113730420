import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../../hooks";
import { selectCurrentUser } from "../../authentication/services";
import { useGetAllProjectsQuery } from "../services";
import { checkProjectLimitNotExceed, getUserProjectLimit } from "../util";

const useProjectList = () => {
    const navigate = useNavigate();
    const [searchVal, setSearchVal] = useState("");

    const currentUser = useAppSelector(selectCurrentUser);
    const isEnterpriseUser = currentUser?.enterpriseAccountId;
    const enterpriseReader = currentUser?.enterpriseRoles?.[0] === "ORG_READER";
    // > ================= API =================
    const { data } = useGetAllProjectsQuery({ page: 1, limit: 300 });

    // > ================= Data Process =================

    // - API not sort by now, so process sort in front-end
    const projectsList = data?.data?.records?.slice().sort((a, b) => {
        const createdAtA = new Date(a.created_at);
        const createdAtB = new Date(b.created_at);
        // Compare the 2 dates
        if (createdAtA > createdAtB) return -1;
        if (createdAtA < createdAtB) return 1;
        return 0;
    });

    // 專案的數量限制只看 project limits ，不是看 role 來限制，因為Role= `user` 有可能可以有無限多個狀態
    const checkUserCanCreateProject = () => {
        if (!currentUser || !projectsList) return false;
        const userProjectLimit = getUserProjectLimit(currentUser.email, currentUser.role);
        return checkProjectLimitNotExceed(projectsList.length, userProjectLimit);
    };

    // > ================= Event Handler =================
    const handleCreate = () => {
        const canCreate = checkUserCanCreateProject();
        if (canCreate) {
            navigate("/create-project");
            return;
        }
        if (currentUser?.isFirstTimeLogin) {
            navigate("/init-project");
            return;
        }
        navigate("/account/promote-upgrade");
    };
    const handleClick = (projectId: string) => {
        const goChatRoom = isEnterpriseUser && enterpriseReader;
        navigate(`/projects/${projectId}${goChatRoom ? "/chatroom" : ""}`);
    };

    const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => setSearchVal(e.target.value);

    const searchArr =
        projectsList?.filter((item) => {
            return item.title.toLowerCase().includes(searchVal);
        }) || [];
    return {
        searchVal,
        handleSearchInput,
        projectsList,
        searchArr,
        handleClick,
        handleCreate,
        isFirstTimeLogin: currentUser?.isFirstTimeLogin,
    };
};
export default useProjectList;
