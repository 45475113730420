import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import MainTabs from "../../components/tab/MainTabs";
import { EnterprisePermissionControl } from "../../features/authentication/components/permissionControl";
import { selectCurrentUser } from "../../features/authentication/services";
import { AMAZE_ACTION } from "../../features/authentication/types";
import { checkEnterprisePermission } from "../../features/authentication/utils";
import { useAppSelector } from "../../hooks";
import { ILinkTabProps } from "../../types/interfaces";
import { isProduction } from "../../utils";
interface IProjectTabRoute extends ILinkTabProps {
    action: AMAZE_ACTION;
}
const prodRoute: IProjectTabRoute[] = [
    { labelKey: "PROJECT.Overview", href: "", action: "view" },
    { labelKey: "PROJECT.Settings", href: "settings", action: "update" },
    { labelKey: "VOICEKEYWORD.VoiceKeywords", href: "voice-keywords", action: "view" },
    {
        labelKey: "HOMOPHONE.HomophoneFix",
        href: "homophone-fix",
        action: "view",

        initHref: "homophone-fix",
    },
    {
        labelKey: "ANALYSIS.Data_ANALYSIS",
        href: "data-analysis",
        action: "update",
        initHref: "data-analysis/message-number",
    },
    {
        labelKey: "SCRIPTTEST.ScriptTestAI",
        href: "script-test",
        action: "view",
        initHref: "script-test",
    },
    {
        labelKey: "PROJECT.Project_Records",
        href: "project-records",
        action: "create",
        initHref: "project-records",
    },
];
export const tabRoutes: IProjectTabRoute[] = isProduction ? prodRoute : [...prodRoute];

const ProjectTabs = () => {
    const navigate = useNavigate();

    const { pathname } = useLocation();
    const { projectId } = useParams();

    const currentRoute = pathname?.split("/")[3] || "";
    const enterpriseUserRole = useAppSelector(selectCurrentUser)?.enterpriseRoles;
    const currentUser = useAppSelector(selectCurrentUser);
    const isEnterpriseUser = currentUser?.enterpriseAccountId;
    const permissionRoutes = isEnterpriseUser
        ? tabRoutes.filter((route) => checkEnterprisePermission(enterpriseUserRole, "project", route.action))
        : tabRoutes;

    const [currentTab, setCurrentTab] = useState(0);

    const findIdx = permissionRoutes.findIndex((r) => r.href === currentRoute);

    const handleChange = (newValue: number) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        // if findIdx is not -1, means it's project dashboard index page, show current tab === Dashboard
        if (findIdx !== -1) {
            setCurrentTab(findIdx);
            return;
        }
        setCurrentTab(0);
    }, [findIdx, currentRoute]);
    return (
        <>
            <EnterprisePermissionControl resource='project' action='view' fallback={<></>}>
                <MainTabs
                    currentTab={currentTab}
                    handleChange={handleChange}
                    tabsOnClick={(tabRoute) =>
                        tabRoute.initHref
                            ? navigate(`/projects/${projectId}/${tabRoute.initHref}`)
                            : navigate(`/projects/${projectId}/${tabRoute.href}`)
                    }
                    tabRoutes={permissionRoutes}
                />
            </EnterprisePermissionControl>

            <Outlet />
        </>
    );
};

export default ProjectTabs;
