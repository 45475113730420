import { yupResolver } from "@hookform/resolvers/yup";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { customToast } from "../../../components/notify/NotifyContainer";
import { usePopupContext } from "../../../contexts/popupProvider";
import { useAppDispatch } from "../../../hooks";
import { IApiRes } from "../../../types/interfaces";
import { useEnterpriseLoginMutation } from "../services/authApiSlice";
import { postEnterpriseLogin, setCurrentUser } from "../services/authSlice";
import { IEnterpriseLoginInput } from "../types/interfaces";
import { enterpriseLoginSchema } from "../utils/validation";

// TODO: dev, stage 需要帶 org_serial_number, account, production 先維持 id
const defaultValues = {
    org_serial_number: "",
    account: "",
    password: "",
};
const useEnterpriseLogin = () => {
    const dispatch = useAppDispatch();
    const { setPopups } = usePopupContext();

    const navigate = useNavigate();
    const methods = useForm<IEnterpriseLoginInput>({
        defaultValues,
        resolver: yupResolver(enterpriseLoginSchema()),
        mode: "all",
    });

    const [fetchEnterpriseLogin, { isLoading }] = useEnterpriseLoginMutation();
    //  ================= HANDLER =================
    const onSubmit: SubmitHandler<IEnterpriseLoginInput> = async (data) => {
        try {
            const res = await fetchEnterpriseLogin(data).unwrap();
            const isPasswordExpired = res?.message === "Password_Expired";
            if (res?.status === 1 && res?.message_key === "MESSAGE.OK" && res?.data) {
                dispatch(
                    postEnterpriseLogin({
                        accessToken: res?.data?.amaze_access_token,
                        refreshToken: res?.data?.amaze_refresh_token,
                        enterpriseAccountId: res?.data?.account_id,
                        enterpriseRoles: res?.data?.roles?.map((r) => r.role_id),
                    })
                );
                dispatch(
                    setCurrentUser({
                        isFirstTimeLogin: res?.data?.is_first_time_login,
                        enterpriseAccountId: res?.data?.account_id,
                        role: "enterprise_user",
                        enterpriseRoles: res?.data?.roles?.map((r) => r.role_id),
                    })
                );
                if (isPasswordExpired) {
                    setPopups({ password_expired: true });
                }
                navigate("/");
            }
        } catch (error) {
            customToast.error(((error as FetchBaseQueryError)?.data as IApiRes)?.message_key);
            //if (error instanceof Error) {
            //    customToast.error(error?.message as string);
            //}
        }
    };
    return {
        methods,
        onSubmit,
        isLoading,
    };
};
export default useEnterpriseLogin;
