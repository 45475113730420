import React from "react";

import { DateProvider } from "../features/analyze/context";
import { EnterprisePermissionControl } from "../features/authentication/components/permissionControl";
import { AMAZE_ACTION } from "../features/authentication/types";
import ChatroomPage from "../pages/characters/ChatroomPage";
import ProjectTabs from "../pages/projects/ProjectTabs";
import AnalysisRoutes from "./AnalysisRoutes";
import CharacterRoutes from "./CharacterRoutes";
import KnowledgeRoutes from "./KnowledgeRoutes";
const withProjectViewGuard = (children: React.ReactNode, action: AMAZE_ACTION) => (
    <EnterprisePermissionControl resource='project' action={action}>
        {children}
    </EnterprisePermissionControl>
);
const ProjectPages = {
    Dashboard: React.lazy(() => import("../pages/projects/DashboardPage")),
    VoiceKeyword: React.lazy(() => import("../pages/projects/VoiceKeywordPage")),
    HomophoneFix: React.lazy(() => import("../pages/projects/HomophoneFixPage")),
    ProjectSettings: React.lazy(() => import("../pages/projects/ProjectSettingsPage")),
    ScriptTest: React.lazy(() => import("../pages/projects/ScriptTestPage")),
    ProjectRecords: React.lazy(() => import("../pages/projects/ProjectRecordsPage")),
};
const SettingsRoutes = [
    {
        path: "settings",
        element: withProjectViewGuard(<ProjectPages.ProjectSettings />, "update"),
    },
    { path: "data-analysis", children: AnalysisRoutes },
    { path: "voice-keywords", element: withProjectViewGuard(<ProjectPages.VoiceKeyword />, "view") },
    { path: "homophone-fix", element: withProjectViewGuard(<ProjectPages.HomophoneFix />, "view") },
    { path: "script-test", element: withProjectViewGuard(<ProjectPages.ScriptTest />, "view") },
    {
        path: "project-records",
        element: <DateProvider />,
        children: [{ path: "", element: withProjectViewGuard(<ProjectPages.ProjectRecords />, "create") }],
    },
];

const ProjectRoutes = [
    {
        path: ":projectId",
        children: [
            {
                path: "",
                element: <ProjectTabs />,
                children: [
                    { path: "", element: <ProjectPages.Dashboard /> },
                    {
                        path: "",
                        children: SettingsRoutes,
                    },
                    {
                        path: "chatroom",
                        element: <ChatroomPage needBackButton={false} />,
                    },
                ],
            },
            {
                path: "knowledge",
                children: KnowledgeRoutes,
            },
            {
                path: "characters",
                children: CharacterRoutes,
            },
        ],
    },
];

export default ProjectRoutes;
