import { useParams } from "react-router-dom";

import { customToast } from "../../../components/notify/NotifyContainer";
import { useDateContext } from "../../analyze/context";
import { useIsProjectOwner } from "../../projects/hooks";
import { useDownloadChatHistoryMutation } from "../services/charactersApiSlice";

const useChatHistoryDownload = () => {
    const [fetchDownload, isFetching] = useDownloadChatHistoryMutation();
    const { characterId, projectId } = useParams();
    const isProjectOwner = useIsProjectOwner({ projectId });
    const { date } = useDateContext();
    const formattedDate = date?.format("YYYY-MM") || "";
    const handleDownload = async (downloadAll: boolean) => {
        try {
            if (characterId && projectId) {
                const res = await fetchDownload({
                    character_id: characterId,
                    month: downloadAll ? "" : formattedDate,
                }).unwrap();
                if (res?.status === 1 && res?.data?.file_url) {
                    window.open(res?.data?.file_url, "_blank");
                } else {
                    customToast.error(res?.message || "Something wrong. Please try again later.");
                }
            }
        } catch (err) {
            customToast.error("Something wrong. Please try again later.");
        }
    };
    return { handleDownload, isProjectOwner, isFetching };
};
export default useChatHistoryDownload;
