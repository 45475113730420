import { apiSlice } from "../../../services/api";
import { IGetListRequest, IGetListResponse, IGetResponse } from "../../../types/interfaces";
import { isDev } from "../../../utils";
import {
    ICreateProjectReq,
    ICreateProjectRes,
    IDeleteProjectReq,
    IDownloadExcelReq,
    IDownloadExcelRes,
    IDownloadPictureReq,
    IDownloadPictureRes,
    IInitProjectReq,
    IProjectTypes,
    IQueryProjectParams,
    IUpdateProjectReq,
} from "../types/interfaces";
const DOWNLOAD_API_URL = process.env.REACT_APP_DOWNLOAD_PROJECT_RECORDS_URL;
const Mock_API_URL = "https://dashboard.ai-amaze.com/api/download_photo";
export const projectsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        initFirstProject: builder.mutation<ICreateProjectRes, IInitProjectReq>({
            // create new user gift
            query: (reqData) => ({
                url: "/init_project",
                method: "POST",
                body: { ...reqData },
                //timeout: 1000 * 60 * 10,
            }),
            invalidatesTags: ["Project"],
        }),
        createProject: builder.mutation<{ success: boolean; projects: string }, ICreateProjectReq>({
            query: (reqData) => ({
                url: "/create_project",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Project"],
        }),
        updateProject: builder.mutation<{ success: boolean; projects: string }, IUpdateProjectReq>({
            query: (reqData) => ({
                url: "/project/update_record",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Project"],
        }),
        deleteProject: builder.mutation<{ success: boolean }, IDeleteProjectReq>({
            query: (reqData) => ({
                url: "/project/delete_project",
                method: "DELETE",
                body: { ...reqData },
            }),
            invalidatesTags: ["Project"],
        }),
        getAllProjects: builder.query<IGetListResponse<IProjectTypes[]>, IGetListRequest>({
            query: (reqData) => ({
                url: "/project/list_records",
                method: "POST",
                body: { ...reqData },
            }),
            providesTags: ["Project"],
        }),
        getProject: builder.query<IGetResponse<IProjectTypes>, IQueryProjectParams>({
            query: (params) => ({
                url: "/project/get_record",
                params,
            }),
            providesTags: ["Project"],
        }),
        downloadPicture: builder.mutation<IDownloadPictureRes, IDownloadPictureReq>({
            query: (reqData) => ({
                url: isDev ? Mock_API_URL : `${DOWNLOAD_API_URL}/download_photo`,
                method: "POST",
                body: { ...reqData },
            }),
        }),
        downloadExcel: builder.mutation<IDownloadExcelRes, IDownloadExcelReq>({
            query: (reqData) => ({
                url: `${DOWNLOAD_API_URL}/download_excel_by_hand`,
                method: "POST",
                body: { ...reqData },
            }),
        }),
    }),
});

export const {
    useInitFirstProjectMutation,
    useGetAllProjectsQuery,
    useCreateProjectMutation,
    useDeleteProjectMutation,
    useGetProjectQuery,
    useUpdateProjectMutation,
    useDownloadPictureMutation,
    useDownloadExcelMutation,
} = projectsApiSlice;
