import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { selectLang } from "../features/langs/services";
import { useAppSelector } from "./reduxHook";
const AMAZE_CAHT_WIDGET_KEY = process.env.REACT_APP_CHAT_WIDGET_KEY;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const WIDGET_CDN_URL = process.env.REACT_APP_WIDGET_CDN_URL;
const StyleOverrides = {
    "chat-window-width": "400px",
    "chat-rwd-window-width": "340px",
    "base-font-size": "16px",
    "message-font-size": "16px",
    "current-small-title-font-size": "14px",
    "current-medium-title-font-size": "16px",
    "current-large-title-font-size": "24px",
    "current-small-subtitle-font-size": "12px",
    "current-medium-subtitle-font-size": "14px",
    "current-large-subtitle-font-size": "18px",
    "current-small-content-font-size": "12px",
    "current-medium-content-font-size": "16px",
    "current-large-content-font-size": "20px",
    "current-small-button-font-size": "12px",
    "current-medium-button-font-size": "16px",
    "current-large-button-font-size": "20px",
    "current-small-hint-font-size": "10px",
    "current-medium-hint-font-size": "12px",
    "current-large-hint-font-size": "14px",
};

const useAmzChatWidget = () => {
    const selectedLang = useAppSelector(selectLang);
    const { t } = useTranslation();

    const chatWidgetScript = useMemo(() => {
        const chatWidgetOptions = {
            base: {
                amaze_key: AMAZE_CAHT_WIDGET_KEY,
                chatApiUrl: BACKEND_URL,
                language: selectedLang,
            },
            ui: {
                inputPlaceholder: t("COMMON.Widget_Placeholder"),
                styleOverrides: StyleOverrides,
            },
            messenger: {
                headerTitle: "AMAZE AI助手",
                headerSubtitle: t("COMMON.TechSupport") + " : AI Amaze",
            },
        };
        return `
          import { render } from "${WIDGET_CDN_URL}/messenger.mjs";
           document.getElementById("chat-widget-root")?.remove()
          render(${JSON.stringify(chatWidgetOptions)});
          `.trim();
    }, [selectedLang, t]);

    useEffect(() => {
        const myScript = document.createElement("script");

        myScript.type = "module";
        myScript.id = "load-chat-widget";
        myScript.text = chatWidgetScript;
        myScript.async = true;

        document.body.appendChild(myScript);

        return () => {
            document.getElementById("load-chat-widget")?.remove();
        };
    }, [chatWidgetScript]);
};
export default useAmzChatWidget;
