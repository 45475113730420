import { createContext, ReactNode, useContext, useState } from "react";

interface IProps {
    popups: { [key: string]: boolean };
    setPopups: (value: { [key: string]: boolean }) => void;
}
export const PopupContext = createContext({} as IProps);

const PopupProvider = ({ children }: { children?: ReactNode }) => {
    const [popups, setPopups] = useState<{ [key: string]: boolean }>({});

    return <PopupContext.Provider value={{ setPopups, popups }}>{children}</PopupContext.Provider>;
};
export const usePopupContext = () => useContext(PopupContext);

export default PopupProvider;
