import React from "react";

import MessageDataLayout from "../features/analyze/components/layout/MessageDataLayout";
import SocialMessageAmount from "../features/analyze/components/socialAnalysis/SocialMessageAmount";
import SocialMessageAnalysis from "../features/analyze/components/socialAnalysis/SocialMessageAnalysis";
import { DateProvider } from "../features/analyze/context";
import { EnterprisePermissionControl } from "../features/authentication/components/permissionControl";

const DataAnalysisPage = React.lazy(() => import("../pages/projects/DataAnalysisPage"));
const AnalysisRoutes = [
    {
        path: "",
        // TODO: 暫時先用 update 來當權限
        element: (
            <EnterprisePermissionControl resource='project' action='update'>
                <DateProvider />
            </EnterprisePermissionControl>
        ),
        children: [
            {
                path: "",
                element: <DataAnalysisPage />,
                children: [
                    {
                        path: "",
                        element: <MessageDataLayout />,
                        children: [
                            {
                                path: "message-number",
                                element: <SocialMessageAmount />,
                            },
                            {
                                path: "message-analysis",
                                element: <SocialMessageAnalysis />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default AnalysisRoutes;
