import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { selectCurrentUser } from "../../features/authentication/services";
import { useGetAllProjectsQuery } from "../../features/projects/services";
import { useAppSelector } from "../../hooks";
import MainWrapper from "./MainWrapper";

const ProjectLayout = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const currentUser = useAppSelector(selectCurrentUser);
    const isAmzUser = !currentUser?.enterpriseAccountId;
    const isEnterpriseUser = currentUser?.enterpriseAccountId;
    const enterpriseReader = currentUser?.enterpriseRoles?.[0] === "ORG_READER";
    const { data: projectsList } = useGetAllProjectsQuery(
        { page: 1, limit: 100 },
        { skip: !!projectId, refetchOnMountOrArgChange: true }
    );

    const defaultFirstProjectId = projectsList?.data?.records?.[0]?.id || "";

    useEffect(() => {
        const goChatRoom = isEnterpriseUser && enterpriseReader && projectsList?.data;
        if (!projectId && projectsList?.data && projectsList?.data?.records?.length > 0) {
            navigate(`/projects/${defaultFirstProjectId}${goChatRoom ? "/chatroom" : ""}`);
        } else if (currentUser?.isFirstTimeLogin && isAmzUser) {
            navigate("/init-project");
        }
    }, [
        currentUser?.isFirstTimeLogin,
        defaultFirstProjectId,
        enterpriseReader,
        isAmzUser,
        isEnterpriseUser,
        navigate,
        projectId,
        projectsList?.data,
    ]);

    return (
        <MainWrapper>
            <Outlet />
        </MainWrapper>
    );
};
export default ProjectLayout;
